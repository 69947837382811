import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { fetchInAppShortcut } from "@/api/inapps/shortcut";
import { MessageShortcut } from "@/api/shortcut/typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppShortcut',
  setup(__props) {

const $q = useQuasar();
const route = useRoute();
const router = useRouter();

const inAppId = computed(() => {
  const rawValue = route.params["inAppId"];
  return Number(rawValue);
});

function routeToInAppView(inAppShortcut: MessageShortcut) {
  // TODO(PNS-2189) Use router.push once issue is fixed
  const redirectLocation = router.resolve({
    name: "InApp",
    params: {
      product: inAppShortcut.product,
      productLine: inAppShortcut.productLine,
      inAppId: inAppId.value,
    },
  });
  window.location.href = redirectLocation.href;
}

function redirectToInAppsList() {
  router.push({
    name: "InAppsList",
    params: {
      product: 1,
      productLine: 1,
    },
  });
  $q.notify({
    type: "negative",
    message: `Failed to fetch InApp with ID: ${inAppId.value}`,
  });
}

fetchInAppShortcut(inAppId.value)
  .then(routeToInAppView)
  .catch(redirectToInAppsList);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})