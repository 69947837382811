import { MessageShortcut, MessageShortcutGuard } from "@/api/shortcut/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

function convertInAppShortcut(data: unknown): MessageShortcut {
  return cast(MessageShortcutGuard, data);
}

export async function fetchInAppShortcut(id: number): Promise<MessageShortcut> {
  const response = await httpClient.get(`/api/inapps/message/${id}/`);

  await throwIfBadResponse(response);

  const result = await response.json();

  return convertInAppShortcut(result);
}
